import React, { Component } from 'react';
import { API_LENDER_LIST, API_ADD_PROGRAM } from '../../../../utils/APIUrls';
import { GET, POST } from '../../../../utils/webAPI.service';
export default class AddProgram extends Component {
  state = {
    lenderList: [],
  };

  onInputChange = (e) => {
    const { name, value } = e.target;

    let newValue = value;
    if (name === 'programLimit' || name === 'programValidity') {
      newValue = value.replace(/\D/g, ''); // Remove non-digit characters
    }
    this.setState({
      [name]: newValue,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let {
      match: {
        params: { appId },
      },
    } = this.props || {};
    let { lenderId, programType, programValidity, programLimit } = this.state;
    let sendData = {
      b2bAppId: appId,
      lenderId,
      programLimit,
      programType,
      programValidity,
    };
    POST(API_ADD_PROGRAM, sendData)
      .then(({ data }) => {
        if (data?.code === 200) {
          this.setState({
            paymentModalTable: data,
          });
          alert('Program added successfully');
          this.props.fetchDetails();
          this.props.closeAction();
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  componentDidMount = () => {
    GET(API_LENDER_LIST)
      .then(({ data }) => {
        if (data?.code === 200) {
          this.setState({
            lenderList: data?.data,
          });
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  render() {
    let { lenderList, programValidity, programLimit } = this.state;
    let {
      match: {
        params: { appId },
      },
    } = this.props || {};
    return (
      <div className='modal-wrapper'>
        <h3 className='card_heading'>Add New Program</h3>
        <div className='mt-4 font-weight-bold h4'>Application Id : {appId}</div>
        <form onSubmit={(e) => this.onSubmit(e)}>
          <div className='row mt-4'>
            <div className='col-md-6'>
              <div className='input_heading'>
                Please Select Program Type
                <select
                  className={'select-box'}
                  name={'programType'}
                  onChange={this.onInputChange}
                  required
                >
                  <option disabled selected>
                    Select Program Type
                  </option>
                  <option value={'supplier'}>Supplier</option>
                  <option value={'buyer'}>Buyer</option>
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='input_heading'>
                Please Select Lender
                <select
                  className={'select-box'}
                  name={'lenderId'}
                  onChange={this.onInputChange}
                  required
                >
                  <option disabled selected>
                    Please Select Lender
                  </option>
                  {lenderList.map((el, index) => {
                    return (
                      <option value={el?.lenderId} key={`lenderList-${index}`}>
                        {' '}
                        {el.lenderName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='input_heading'>
                Please Enter Program Validity(Days)
                <input
                  placeholder='Please Enter Program Validity in Days'
                  required
                  value={programValidity}
                  name='programValidity'
                  onChange={this.onInputChange}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='input_heading'>
                Master Limit Amount
                <input
                  placeholder='Enter Master Limit Amount'
                  required
                  value={programLimit}
                  name='programLimit'
                  onChange={this.onInputChange}
                />
              </div>
            </div>
          </div>
          <div className='save_button mt-5'>
            <button className='save_button  button-success' type='submit'>
              Confirm
            </button>
          </div>
        </form>
      </div>
    );
  }
}
