import React, { Component } from 'react';
import Button from '../../../components/Button';
import { GET, POST } from '../../../utils/webAPI.service';
import { loaderModal } from '../../../store/actions';
import {
  API_PROGRAM_LIST,
  API_SUB_PROGRAM_DETAILS,
  API_ACTIVATE_VENDOR_LIMIT,
} from '../../../utils/APIUrls';
import SubProgramModal from './SubProgramModal';
import CredoModal from '../../../components/CredoModal';
import { getVendorLimit } from './manageUser.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import { getCustomerAndSupplierList } from './manageUser.actions';

class VendorOffer extends Component {
  state = {
    programList: [],
    subProgram: null,
    isModalOpen: false,
    modalType: null,
    showVendorOffer: false,
    subventionFlag: 'No',
    subventionBreakCharges: 'Yes',
    flatChargeFlag: 'No',
    marginFlag: 'No',
    settlementSubOrder: null,
    isSetupFeesUpfront: null,
  };

  settlementSubOrders = (ele) => {
    if (ele === 'vertical_ipc') {
      return 'Vertical IPC';
    } else if (ele === 'vertical_pic') {
      return 'Vertical PIC';
    }
    return null;
  };

  componentDidMount() {
    let {
      actions,
      stateData: { rowData },
      propsData,
      supplierList,
    } = this.props || {};
    let { getVendorLimit } = actions;
    let { match } = propsData;
    let { params } = match;
    let { userId } = params;
    if (!rowData?.limitCreated) {
      GET(API_PROGRAM_LIST(supplierList?.supplierId))
        .then(({ data }) => {
          if (data.code === 200) {
            this.setState({
              programList: data?.data?.responseList,
            });
          } else {
            alert(data?.message);
          }
        })
        .catch((err) => {
          throw err;
        });
    }
    if (rowData?.limitCreated) {
      getVendorLimit(userId, rowData?.uniqueId);
    }
  }

  isSetupFeesUpfront = (ele) => {
    if (ele === true) {
      return 'Yes';
    } else if (ele === false) {
      return 'No';
    } else {
      return '_';
    }
  };

  onInputChange = async (e) => {
    const { name, value } = e.target;
    await this.setState({
      [name]: value,
    });
    if (name === 'programName') {
      this.fetchSubprogram();
    }
    if (name === 'subProgramName') {
      this.filterSubprogram();
    }
  };

  filterSubprogram = async () => {
    let { subProgram, subProgramName } = this.state;
    let { data } = subProgram || {};
    let { subProgramList = [] } = data || {};
    const subProgramView = subProgramList.find(
      (ele) => ele?.subProgramName === subProgramName
    );
    const subventionFlag = subProgramView?.subvention?.applicable
      ? 'Yes'
      : 'No';
    const marginFlag = subProgramView?.margin?.applicable ? 'Yes' : 'No';
    this.setState({ subventionFlag, marginFlag });
    this.setState({
      subProgramDetails: subProgramView,
      subventionFlag,
      marginFlag,
    });
  };

  fetchSubprogram = () => {
    let { supplierList } = this.props || {};
    let { programName } = this.state;
    GET(API_SUB_PROGRAM_DETAILS(supplierList?.supplierId, programName))
      .then(async ({ data }) => {
        if (data.code === 200) {
          await this.setState({
            subProgram: data,
          });
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  vendorOffer = (e) => {
    e.preventDefault();
    let {
      propsData,
      stateData: { rowData = {} },
      supplierList,
      actions,
    } = this.props;
    let { match } = propsData;
    let { params } = match;
    let { loanId, userId } = params;
    let { getCustomerAndSupplierList } = actions;
    let {
      extension,
      extensionInterestRate,
      gracePeriodInDays,
      interestRate,
      limitValidity,
      marginPercentage,
      maxInvoiceAmount,
      minInvoiceAmount,
      overdueInterestRate,
      programName,
      subProgramName,
      subventionIntRate,
      subventionPeriodInDays,
      tenure,
    } = this.state;
    let dataToSend = {
      extension,
      extensionInterestRate,
      gracePeriodInDays,
      interestRate,
      limitValidity,
      loanId,
      marginPercentage,
      maxInvoiceAmount,
      minInvoiceAmount,
      mobileNumber: rowData?.mobileNumber,
      overdueInterestRate,
      programName,
      subProgramName,
      subventionIntRate,
      subventionPeriodInDays,
      supplierId: supplierList?.supplierId,
      tenure,
      vendorId: rowData?.uniqueId,
    };
    if (Number(minInvoiceAmount) >= Number(maxInvoiceAmount)) {
      alert('Max Invoice Amount should be greater than the Min Invoice Amount');
      return;
    }
    POST(API_ACTIVATE_VENDOR_LIMIT, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          alert('Limit created successfully');
          getCustomerAndSupplierList(userId, 'supplier', 1);
          this.props.closeAction();
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  toggleModal = (modalType) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
    });
  };

  render() {
    let {
      stateData: { rowData = {} },
      vendorLimitDetails,
    } = this.props || {};
    let {
      programList,
      subProgram,
      subProgramDetails,
      isModalOpen,
      modalType,
      showVendorOffer,
    } = this.state;
    let { data } = subProgram || {};
    let { subProgramList = [] } = data || {};
    let { subventionFlag, marginFlag } = this.state;
    return (
      <>
        <div className='offer_wrapper container'>
          <h3 className='  m-heading'>Activate Limit</h3>

          <div className='row ml-2'>
            <div className='col-md-3 text-grey '>
              Supplier Id
              <div className='text-bold'>{rowData?.uniqueId ?? '-'}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Supplier Name
              <div className='text-bold'>{rowData?.name ?? '-'}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Phone Number
              <div className='text-bold'>{rowData?.mobileNumber ?? '-'}</div>
            </div>
          </div>
          {!rowData?.limitCreated ? (
            <div className='row ml-1'>
              <div className='col-md-3 w-75'>
                <div className=' text-grey'>
                  Program
                  <select
                    className={'select-box'}
                    name={'programName'}
                    onChange={this.onInputChange}
                    required
                    disabled={showVendorOffer}
                  >
                    <option disabled selected>
                      Select Program Type
                    </option>
                    {programList?.map((ele, i) => {
                      return <option value={ele}>{ele}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className='col-md-3 w-75 '>
                <div className=' text-grey'>
                  Sub-Program
                  <select
                    className={'select-box'}
                    name={'subProgramName'}
                    onChange={this.onInputChange}
                    required
                    disabled={showVendorOffer}
                  >
                    <option disabled selected>
                      Select Sub-Program Type
                    </option>
                    {subProgramList.map((element, i) => {
                      return (
                        <option value={element?.subProgramName}>
                          {element?.subProgramName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className='col-4 mt-4 d-flex align-items-center justify-content-between'>
                <span
                  className='capsule capsule-blue mr-3'
                  onClick={() => this.toggleModal(SubProgramModal)}
                >
                  view
                </span>
                <Button
                  disabled={showVendorOffer}
                  onClick={() =>
                    this.setState((prevState) => ({
                      ...prevState,
                      showVendorOffer: !prevState?.showVendorOffer,
                    }))
                  }
                >
                  Create Vendor Offer
                </Button>
              </div>
            </div>
          ) : null}

          {!rowData?.limitCreated && showVendorOffer ? (
            <>
              <form onSubmit={(e) => this.vendorOffer(e)}>
                <div className='row col-11 pt-4 '>
                  <div className='col-md-4 '>
                    <div className=' text-grey'>
                      Limit Validity
                      <input
                        min={subProgramDetails?.minLimitTenureInDays}
                        max={subProgramDetails?.maxLimitTenureInDays}
                        placeholder='Enter Days'
                        value={this.state.limitValidity}
                        type='number'
                        required
                        onChange={(e) =>
                          this.setState({
                            limitValidity: e.target.value,
                          })
                        }
                      />
                      <div className='limit-parameters'>
                        Between {subProgramDetails?.minLimitTenureInDays} -
                        {subProgramDetails?.maxLimitTenureInDays} days
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 '>
                    <div className=' text-grey'>
                      Standard Tenure
                      <input
                        min={subProgramDetails?.minTenure}
                        max={subProgramDetails?.maxTenure}
                        placeholder='Enter Days'
                        value={this.state.tenure}
                        type='number'
                        required
                        onChange={(e) =>
                          this.setState({
                            tenure: e.target.value,
                          })
                        }
                      />
                      <div className='limit-parameters'>
                        Between {subProgramDetails?.minTenure} -
                        {subProgramDetails?.maxTenure} days
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className=' text-grey'>
                      Interest Rate (APR%)
                      <input
                        min={subProgramDetails?.minIntRate}
                        max={subProgramDetails?.maxIntRate}
                        placeholder=' Enter Percentage'
                        value={this.state.interestRate}
                        type='number'
                        required
                        onChange={(e) =>
                          this.setState({
                            interestRate: e.target.value,
                          })
                        }
                      />
                      <div className='limit-parameters'>
                        Between {subProgramDetails?.minIntRate} -
                        {subProgramDetails?.maxIntRate} %
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className=' text-grey'>
                      Grace Period
                      <input
                        min={subProgramDetails?.minGracePeriodDays}
                        max={subProgramDetails?.maxGracePeriodDays}
                        placeholder='Enter Percentage'
                        value={this.state.gracePeriodInDays}
                        type='number'
                        required
                        onChange={(e) =>
                          this.setState({
                            gracePeriodInDays: e.target.value,
                          })
                        }
                      />
                      <div className='limit-parameters'>
                        Between {subProgramDetails?.minGracePeriodDays} -
                        {subProgramDetails?.maxGracePeriodDays} days
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='text-grey'>
                      Repayment Settlement Sub Order
                      <input
                        value={this.settlementSubOrders(
                          subProgramDetails?.settlementSubOrder
                        )}
                        required
                        disabled
                      />
                    </div>
                  </div>
                  <div className='col-md-4 '>
                    <div className='text-grey'>
                      Extension
                      <input
                        min={subProgramDetails?.minExtension}
                        max={subProgramDetails?.maxExtension}
                        placeholder='Enter Days'
                        value={this.state.extension}
                        type='number'
                        required
                        onChange={(e) =>
                          this.setState({
                            extension: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className='limit-parameters'>
                      Between {subProgramDetails?.minExtension} -
                      {subProgramDetails?.maxExtension} days
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className=' text-grey'>
                      Extension Interest Rate (APR%)
                      <input
                        max={subProgramDetails?.maxExtensionInterestRate}
                        min={subProgramDetails?.minExtensionInterestRate}
                        required
                        placeholder='Enter Percentage'
                        value={this.state.extensionInterestRate}
                        type='number'
                        onChange={(e) =>
                          this.setState({
                            extensionInterestRate: e.target.value,
                          })
                        }
                      />
                      <div className='limit-parameters'>
                        Between {subProgramDetails?.minExtensionInterestRate}% -
                        {subProgramDetails?.maxExtensionInterestRate}%
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className=' text-grey'>
                      Overdue Interest Rate (APR%)
                      <input
                        max={subProgramDetails?.maxOverDueIntRate}
                        min={subProgramDetails?.minOverDueIntRate}
                        required
                        placeholder='Enter Percentage'
                        value={this.state.overdueInterestRate}
                        type='number'
                        onChange={(e) =>
                          this.setState({
                            overdueInterestRate: e.target.value,
                          })
                        }
                      />
                      <div className='limit-parameters'>
                        Between {subProgramDetails?.minOverDueIntRate}% -
                        {subProgramDetails?.maxOverDueIntRate}%
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className=' text-grey'>
                      Minimum Invoice Amount
                      <input
                        placeholder=' Enter Min Invoice Amount'
                        value={this.state.minInvoiceAmount}
                        required
                        type='number'
                        onChange={(e) =>
                          this.setState({ minInvoiceAmount: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className=' text-grey'>
                      Maximum Invoice Amount
                      <input
                        placeholder=' Enter  Max Invoice Amount'
                        value={this.state.maxInvoiceAmount}
                        required
                        type='number'
                        onChange={(e) =>
                          this.setState({ maxInvoiceAmount: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className='row col-11 mt-5 pl-4'>
                  <div className='col-md-4'>
                    <div className=' text-grey'>
                      Subvention
                      <div className='col-md-12 d-flex '>
                        <input
                          value='Yes'
                          name='subvention'
                          type='radio'
                          checked={subventionFlag === 'Yes'}
                        />
                        <div>Yes</div>
                      </div>
                      <div className='col-md-12 d-flex'>
                        <input
                          value='No'
                          name='subvention'
                          type='radio'
                          checked={subventionFlag === 'No'}
                        />
                        <div>No</div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className=' text-grey'>
                      Subvention Period
                      <input
                        placeholder='Enter Days '
                        value={this.state.subventionPeriodInDays}
                        type='number'
                        required
                        onChange={(e) =>
                          this.setState({
                            subventionPeriodInDays: e.target.value,
                          })
                        }
                        disabled={subventionFlag === 'Yes' ? false : true}
                      />
                      <div className='limit-parameters'>
                        Between{' '}
                        {subProgramDetails?.subvention?.periodInDays?.min} days
                        -{subProgramDetails?.subvention?.periodInDays?.max} days
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 '>
                    <div className=' text-grey'>
                      Subvention Interest Rate(APR%)
                      <input
                        max={subProgramDetails?.subvention?.rateOfInt?.max}
                        min={subProgramDetails?.subvention?.rateOfInt?.min}
                        placeholder=' Enter Percentage'
                        type='number'
                        required
                        value={this.state.subventionIntRate}
                        onChange={(e) =>
                          this.setState({
                            subventionIntRate: e.target.value,
                          })
                        }
                        disabled={subventionFlag === 'Yes' ? false : true}
                      />
                      <div className='limit-parameters'>
                        Between {subProgramDetails?.subvention?.rateOfInt?.min}%
                        -{subProgramDetails?.subvention?.rateOfInt?.max}%
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 '></div>
                  <div className='col-md-4 '>
                    <div className=' text-grey'>
                      Subvention Bearer
                      <input
                        step={0.1}
                        value={
                          subventionFlag === 'Yes'
                            ? subProgramDetails?.subvention?.bearer === 'seller'
                              ? `Seller`
                              : subProgramDetails?.subvention?.bearer ===
                                'buyer'
                              ? `Buyer`
                              : subProgramDetails?.subvention?.bearer ===
                                'third_party'
                              ? `Third Party`
                              : null
                            : ''
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='col-md-4 '>
                    <div className=' text-grey'>
                      Interest Collected
                      <input
                        step={0.1}
                        value={
                          subventionFlag === 'Yes'
                            ? subProgramDetails?.subvention?.collection ===
                              'upfront'
                              ? `Upfront`
                              : subProgramDetails?.subvention?.collection ===
                                'rear_ended'
                              ? `Rear-Ended`
                              : null
                            : ''
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <div className='row col-11 mt-5 pl-4'>
                  <div className='col-4 '>
                    <div className=' text-grey'>
                      Margin
                      <div className='col-md-12 d-flex '>
                        <input
                          value='Yes'
                          name='marginFlag'
                          type='radio'
                          checked={marginFlag === 'Yes'}
                        />
                        <div>Yes</div>
                      </div>
                      <div className='col-12 d-flex'>
                        <input
                          value='No'
                          name='marginFlag'
                          type='radio'
                          checked={marginFlag === 'No'}
                        />
                        <div>No</div>
                      </div>
                    </div>
                  </div>
                  <div className='col-4 '>
                    <div className=' text-grey'>
                      Margin Percentage
                      <input
                        max={subProgramDetails?.margin?.perMargin?.max}
                        min={subProgramDetails?.margin?.perMargin?.min}
                        placeholder='Enter Percentage '
                        value={this.state.marginPercentage}
                        type='number'
                        required
                        onChange={(e) =>
                          this.setState({
                            marginPercentage: e.target.value,
                          })
                        }
                        disabled={marginFlag === 'Yes' ? false : true}
                      />
                      <div className='limit-parameters'>
                        Between {subProgramDetails?.margin?.perMargin?.min}% -
                        {subProgramDetails?.margin?.perMargin?.max}%
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <button className='submit_button' type='submit'>
                    Activate Limit
                  </button>
                </div>
              </form>
            </>
          ) : rowData?.limitCreated ? (
            <>
              <div className='row col-11 ml-2 main-row  '>
                <div className='col-md-4 main-row_inner'>
                  Limit Validity
                  <div className='text-bold'>
                    {vendorLimitDetails?.terms?.limitTenureInDays ?? '-'} days
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className=' text-grey'>
                    Standard Tenure
                    <div className='text-bold'>
                      {vendorLimitDetails?.terms?.tenureInDays ?? '-'} days
                    </div>
                  </div>
                </div>
                <div className='col-md-4'></div>
                <div className='col-md-4 mt-3'>
                  <div className=' text-grey'>
                    Grace Period
                    <div className='text-bold'>
                      {vendorLimitDetails?.terms?.gracePeriodInDays ?? '-'} days
                    </div>
                  </div>
                </div>
                <div className='col-md-4 mt-3 main-row_inner'>
                  Repayment Settlement Sub Order
                  <div className='text-bold'>
                    {this.settlementSubOrders(
                      vendorLimitDetails?.terms?.settlementSubOrder
                    ) || '-'}
                  </div>
                </div>
                <div className='col-md-4 mt-3'></div>
                <div className='col-md-4 mt-3 main-row_inner'>
                  Extension
                  <div className='text-bold'>
                    {vendorLimitDetails?.terms?.extension ?? '-'} days
                  </div>
                </div>
                <div className='col-md-4 mt-3 main-row_inner'>
                  Extension Interest Rate (APR%)
                  <div className='text-bold'>
                    {vendorLimitDetails?.terms?.extensionInterestRate ?? '-'} %
                  </div>
                </div>
                <div className='col-md-4 mt-3 main-row_inner'>
                  Overdue Interest Rate (APR%)
                  <div className='text-bold'>
                    {vendorLimitDetails?.terms?.overdueInterestRate ?? '-'} %
                  </div>
                </div>
                <div className='col-md-4 mt-3 main-row_inner'>
                  Minimum Invoice Amount
                  <div className='text-bold'>
                    {rupeeDecimalFormatter(
                      vendorLimitDetails?.terms?.minInvoiceAmount,
                      '₹'
                    )}
                  </div>
                </div>
                <div className='col-md-4 mt-3 main-row_inner'>
                  Maximum Invoice Amount
                  <div className='text-bold'>
                    {rupeeDecimalFormatter(
                      vendorLimitDetails?.terms?.maxInvoiceAmount,
                      '₹'
                    )}
                  </div>
                </div>
                <div className='col-md-4 mt-3 main-row_inner'></div>{' '}
                {/* psedo Element */}
                <div className='col-md-4 main-row_inner'>
                  Subvention
                  <div className='text-bold'>
                    {vendorLimitDetails?.terms?.subvention?.isSubvented
                      ? 'Yes'
                      : 'No'}
                  </div>
                </div>
                <div className='col-md-4 mt-3 main-row_inner'>
                  Subvention Period
                  <div className='text-bold'>
                    {vendorLimitDetails?.terms?.subvention?.period} days
                  </div>
                </div>
                <div className='col-md-4 mt-3 main-row_inner'>
                  Subvention Interest Rate (APR%)
                  <div className='text-bold'>
                    {vendorLimitDetails?.terms?.subvention?.roi} %
                  </div>
                </div>
                <div className='col-md-4 mt-3 main-row_inner'>
                  Subvention Bearer
                  <div className='text-bold'>
                    {vendorLimitDetails?.terms?.subvention?.bearer === 'seller'
                      ? `Seller`
                      : vendorLimitDetails?.terms?.subvention?.bearer ===
                        'buyer'
                      ? `Buyer`
                      : vendorLimitDetails?.terms?.subvention?.bearer ===
                        'third_party'
                      ? `Third Party`
                      : '-'}
                  </div>
                </div>
                <div className='col-md-4 mt-3 main-row_inner'>
                  Interest Collected
                  <div className='text-bold'>
                    {vendorLimitDetails?.terms?.subvention?.collection ===
                    'upfront'
                      ? `Upfront`
                      : vendorLimitDetails?.terms?.subvention?.collection ===
                        'rear_ended'
                      ? `Rear-Ended`
                      : '-'}
                  </div>
                </div>
                <div className='col-md-4 mt-3 main-row_inner'></div>
                <div className='col-md-4 mt-3 main-row_inner'>
                  Margin
                  <div className='text-bold'>
                    {vendorLimitDetails?.terms?.margin?.isApplicable
                      ? 'Yes'
                      : 'No'}
                  </div>
                </div>
                <div className='col-md-4 mt-3 main-row_inner'>
                  Margin Percentage
                  <div className='text-bold'>
                    {vendorLimitDetails?.terms?.margin?.percentage} %
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <CredoModal
            isOpen={isModalOpen}
            styles={{
              content: {
                width: '70%',
                height: 'max-content',
              },
            }}
            closeAction={() => this.toggleModal('')}
            RenderingComponent={modalType}
            subProgramDetails={subProgramDetails}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ manageUserReducer }) => ({
  vendorLimitDetails: manageUserReducer.vendorLimitDetails,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getVendorLimit,
      getCustomerAndSupplierList,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorOffer);
