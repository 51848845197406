import React, { Component } from 'react';
import ReactTable from 'react-table';
import Button from '../../../../components/Button';
import SearchIcon from '../../../../assets/images/SearchIcon.svg';
import InvoiceButton from '../../../../assets/images/invoice-button.png';
import UnpaidInvoiceModal from './InvoiceModal/UnpaidModal';
import CredoModal from '../../../../components/CredoModal';
import { getInvoicesList } from './activeParties.action';
import File from '../../../../assets/images/form.svg';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { rupeeDecimalFormatter } from '../../../../utils/utility';
class Unpaid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      showDatePicker: false,
      isModalOpen: false,
      invoiceData: {},
      endDate: '',
      pageNumber: 1,
      searchParam: '',
      startDate: '',
      types: 'unpaid',
      searchValue: '',
    };
  }

  componentDidMount = () => {
    const {
      actions,
      match: { params },
    } = this.props;
    let { endDate, pageNumber, searchParam, startDate, types, searchValue } =
      this.state;
    const { getInvoicesList } = actions;
    getInvoicesList(
      endDate,
      pageNumber,
      searchParam,
      startDate,
      types,
      searchValue,
      params?.anchorId,
      params?.buyerId
    );
  };

  onResetFilter = () => {
    let { types } = this.state;
    const {
      actions,
      match: { params },
    } = this.props;
    const { getInvoicesList } = actions;
    this.setState(
      {
        endDate: '',
        pageNumber: 1,
        searchParam: '',
        startDate: '',
        searchValue: '',
      },
      () =>
        getInvoicesList(
          '',
          1,
          '',
          '',
          types,
          '',
          params?.anchorId,
          params?.buyerId
        )
    );
  };

  getPageData = async (type, totalPages) => {
    const {
      actions,
      match: { params },
    } = this.props;
    let { endDate, pageNumber, searchParam, startDate, types, searchValue } =
      this.state;
    const { getInvoicesList } = actions;
    let updatedPageNumber = pageNumber;
    if (type === 'next' && pageNumber < totalPages) {
      updatedPageNumber = pageNumber + 1;
    } else if (type === 'prev' && pageNumber > 1) {
      updatedPageNumber = pageNumber - 1;
    }
    await this.setState({ pageNumber: updatedPageNumber });
    getInvoicesList(
      endDate,
      updatedPageNumber,
      searchParam,
      startDate,
      types,
      searchValue,
      params?.anchorId,
      params?.buyerId
    );
  };

  toggleModal = (modalType, original) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      invoiceData: original,
    });
  };

  onSearch = async (e) => {
    e.preventDefault();
    const {
      actions,
      match: { params },
    } = this.props;
    await this.setState({
      pageNumber: 1,
    });
    let { endDate, pageNumber, searchParam, startDate, types, searchValue } =
      this.state;
    const { getInvoicesList } = actions;
    if (searchParam === '') {
      alert('Please select search type');
      return;
    }
    getInvoicesList(
      endDate,
      pageNumber,
      searchParam,
      startDate,
      types,
      searchValue,
      params?.anchorId,
      params?.buyerId
    );
  };

  onDateFilter = async (e) => {
    e.preventDefault();
    const {
      actions,
      match: { params },
    } = this.props;
    let { endDate, startDate } = this.state;
    const formattedEndDate = endDate
      ? endDate.split('-').reverse().join('/')
      : '';
    const formattedStartDate = startDate
      ? startDate.split('-').reverse().join('/')
      : '';

    await this.setState((prevState) => ({
      ...prevState,
      showDatePicker: !prevState.showDatePicker,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      pageNumber: 1,
    }));
    let { pageNumber, searchParam, types, searchValue } = this.state;

    const { getInvoicesList } = actions;
    getInvoicesList(
      formattedEndDate,
      pageNumber,
      searchParam,
      formattedStartDate,
      types,
      searchValue,
      params?.anchorId,
      params?.buyerId
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value?.trim(),
    });
  };

  render() {
    let { showDatePicker, isModalOpen, modalType, pageNumber, invoiceData } =
      this.state;
    let { invoicesList } = this.props || {};
    let { invoiceDetails, numberOfPages } = invoicesList || {};
    const unpaidColumn = [
      {
        Header: () => (
          <>
            <div className='ml-4'>Party Name</div>
          </>
        ),
        accessor: 'partyName',
        Cell: (props) => {
          let { original } = props;
          let { partyName } = original;
          return <>{partyName || '-'}</>;
        },
      },
      {
        Header: () => (
          <>
            <div className='ml-2'>Invoice Number</div>
          </>
        ),
        accessor: 'invoiceNumber',
        Cell: ({ original }) => {
          return (
            <>
              <img className='mr-2 img-icon' src={File} />
              <span
                className='invoice_number'
                onClick={() => {
                  this.toggleModal(UnpaidInvoiceModal, original);
                }}
              >
                {original?.invoiceNumber}
              </span>
            </>
          );
        },
      },
      {
        Header: 'Uploaded Date',
        accessor: 'invoiceUploadDate',
      },
      {
        Header: 'InvoiceAmount',
        accessor: 'invoiceAmount',
        Cell: (props) => {
          let { original } = props;
          let { invoiceAmount } = original;
          return (
            <>
              <div className='ml-4'>
                {rupeeDecimalFormatter(invoiceAmount, '₹')}
              </div>
            </>
          );
        },
      },
    ];
    return (
      <>
        <div className='row interest_charges_wrapper flex'>
          <div className='col-10'>
            <div className='row'>
              <div className='col-6'>
                <form onSubmit={(e) => this.onSearch(e)}>
                  <div className='search-form'>
                    <select
                      className='select '
                      onChange={this.handleChange}
                      name='searchParam'
                      value={this.state.searchParam || ''}
                      required
                    >
                      <option value='' disabled selected>
                        Search By
                      </option>
                      <option value={'invoiceNumber'}>Invoice No</option>
                    </select>
                    <input
                      className='select input'
                      required={true}
                      placeholder='Search'
                      name='searchValue'
                      value={this.state.searchValue}
                      onChange={this.handleChange}
                    />
                    <button type='submit' className='submit'>
                      <img src={SearchIcon} alt='icon' />
                    </button>
                  </div>
                </form>
              </div>
              <div className='col-4'>
                <Button className='mt-1' onClick={this.onResetFilter}>
                  Reset
                </Button>
              </div>
            </div>
          </div>
          <div className='col-2 card_heading_secondary_right'>
            <img
              src={InvoiceButton}
              alt='icon'
              className='icon_image '
              onClick={() =>
                this.setState((prevState) => ({
                  ...prevState,
                  showDatePicker: !prevState.showDatePicker,
                }))
              }
            />
          </div>
        </div>
        {showDatePicker ? (
          <div className='row d-flex justify-content-end'>
            <div className='card date-picker col-4'>
              <div className='row  '>
                <div className='col text-grey mt-4'>
                  Start Date
                  <input
                    className='date'
                    type='date'
                    onChange={(e) =>
                      this.setState({
                        startDate: e.target.value,
                      })
                    }
                  ></input>
                </div>
                <div className='col text-grey mt-4'>
                  End Date
                  <input
                    className='date'
                    type='date'
                    onChange={(e) =>
                      this.setState({
                        endDate: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className='row mt-3 ml-2'>
                <Button onClick={(e) => this.onDateFilter(e)}>OK</Button>
              </div>
            </div>
          </div>
        ) : null}
        <div className='mt-4 interest_charges_wrapper'>
          <ReactTable
            data={invoiceDetails}
            columns={unpaidColumn}
            defaultPageSize={10}
            showPagination={false}
            sortable={false}
          />
          <ul className={'datatable-pagination'}>
            <li>
              <button
                disabled={!(pageNumber > 1)}
                className={'prev-btn'}
                type={'button'}
                onClick={() => this.getPageData('prev', numberOfPages)}
              >
                Previous
              </button>
            </li>
            <li>
              <span>
                Page No. {numberOfPages === 0 ? 0 : pageNumber} of{' '}
                {numberOfPages}
              </span>
            </li>
            <li>
              <button
                disabled={!(numberOfPages > pageNumber)}
                className={'next-btn'}
                type={'button'}
                onClick={() => this.getPageData('next', numberOfPages)}
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        <CredoModal
          isOpen={isModalOpen}
          styles={{
            content: {
              width: '80%',
              height: '80%',
            },
          }}
          closeAction={() => this.toggleModal('')}
          RenderingComponent={modalType}
          invoiceData={invoiceData}
          toggleModal={this.toggleModal}
        />
      </>
    );
  }
}

const mapStateToProps = ({ activePartiesReducer }) => ({
  invoicesList: activePartiesReducer?.invoicesList,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getInvoicesList,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Unpaid);
