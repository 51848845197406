import React, { Component } from 'react';
import ReactTable from 'react-table';
import { rupeeDecimalFormatter } from '../../../../utils/utility';
import { API_PAYMENT_MODAL } from '../../../../utils/APIUrls';
import { POST } from '../../../../utils/webAPI.service';

export default class PaymentInvoiceModal extends Component {
  state = {
    paymentModalTable: {},
  };

  componentDidMount() {
    let { paymentModal, supplierId } = this.props || {};
    let sendData = {
      supplierId,
      invoiceNumbers: paymentModal?.listOfInvNumber || [],
    };
    POST(API_PAYMENT_MODAL, sendData)
      .then(({ data }) => {
        if (data?.code === 200) {
          this.setState({
            paymentModalTable: data,
          });
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  }
  render() {
    let { paymentModal } = this.props || {};
    let { paymentModalTable } = this.state;

    const paymentColumns = [
      {
        Header: 'Invoice Number',
        accessor: 'invoiceNumber',
      },
      {
        Header: 'Uploaded By',
        accessor: 'uploadedBy',
      },
      {
        Header: 'Payment Amount',
        accessor: 'paymentAmount',
        Cell: (props) => {
          let { original } = props;
          let { paymentAmount } = original;

          return (
            <>
              <div className='ml-4'>
                {rupeeDecimalFormatter(paymentAmount, '₹')}
              </div>
            </>
          );
        },
      },
    ];
    return (
      <div className='modal-wrapper'>
        <h3 className='card_heading'>Payment Details</h3>
        <div className=' border-bottom'>
          <div className='row'>
            <div className='col-md-3 text-grey'>
              Name
              <div className='text-bold'>{paymentModal?.partyName || '-'}</div>
            </div>
            <div className='col-md-3 text-grey'>
              Invoice Number
              <div className='text-bold'>
                {paymentModal?.listOfInvNumber?.map((e) => (
                  <div>{e}</div>
                ))}
              </div>
            </div>
            <div className='col-md-3 text-grey'>
              Payment Type
              <div className='text-bold'>
                {paymentModal?.paymentType || '-'}
              </div>
            </div>
            <div className='col-md-3 text-grey'>
              Payment Amount
              <div className='text-bold'>
                {rupeeDecimalFormatter(paymentModal?.paymentAmount, '₹')}
              </div>
            </div>
            <div className='col-md-3 text-grey'>
              UTR
              <div className='text-bold'>{paymentModal?.utrNumber || '-'}</div>
            </div>
          </div>
        </div>

        <ReactTable
          columns={paymentColumns}
          data={paymentModalTable?.data?.listOfInvoiceBreakup}
          defaultPageSize={1}
          showPagination={false}
        />
      </div>
    );
  }
}
