import { set } from 'lodash';
import React, { Component } from 'react';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderModal } from '../../../store/actions';
import { API_POST_ACTIVE_LIMIT } from '../../../utils/APIUrls';
import { MONTHS } from '../../../utils/config.properties';
import { POST } from '../../../utils/webAPI.service';
import { getLimitSummary } from '../Invoices/invoices.actions';
import { getLimitAccountNumber } from '../InterestAndCharges/interestAndCharges.actions';
class ConfigureTransactionRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthAmountArray: [
        {
          month: MONTHS[0],
          amount: '',
        },
      ],
      maxNumberOfInvoice: '',
      maxValPerInvoice: '',
      invoiceDate: '',
      maxNumberOfInvInSevenDays: '',
    };
  }

  addMoreMonthAmount = () => {
    this.setState((prevState) => ({
      ...prevState,
      monthAmountArray: [
        ...prevState.monthAmountArray,
        {
          month: MONTHS[0],
          amount: '',
        },
      ],
    }));
  };

  onDeleteHandler = (indexToRemove) => {
    let monthAmountArrayCopy = this.state.monthAmountArray;
    monthAmountArrayCopy.splice(indexToRemove, 1);
    this.setState({ monthAmountArray: monthAmountArrayCopy });
  };

  onInputChange = (value, index, name) => {
    if (value < 0) {
      return;
    }
    let monthAmountArrayCopy = this.state.monthAmountArray;
    set(monthAmountArrayCopy, `[${index}].${name}`, value);
    this.setState((prevState) => ({
      ...prevState,
      monthAmountArray: monthAmountArrayCopy,
    }));
  };

  onCancel = () => {
    this.props.closeAction();
  };

  onSingleInputChange = (event) => {
    const { name, value } = event.target;
    if (value < 0) {
      return;
    }
    const INTEGER_REGEX = /(^$)|(^[0-9]+$)/;
    if (INTEGER_REGEX.test(event.target.value)) {
      this.setState({ [name]: value });
    }
  };

  onSingleInputAmounthHandle = (event) => {
    const { name, value } = event.target;
    if (value < 0) {
      return;
    }
    this.setState({ [name]: value });
  };

  onActivateLimit = async () => {
    const { actions, propsData, getLimitAccountNumber, getLimitSummary } =
      this.props;
    actions.loaderModal(true);
    let { loanId, userId } = propsData.match.params;
    const {
      monthAmountArray,
      maxNumberOfInvoice,
      maxValPerInvoice,
      invoiceDate,
      maxNumberOfInvInSevenDays,
    } = this.state;
    const dataToSend = {
      loanId,
      userId,
      multiValueCriteriaWiseTransactionRules: [
        {
          strategyName: 'low_business_month_wise_total_max_invoice_value',
          ruleKey: 'Maximum invoice total in low business month',
          criteriaWiseAllowedValueList: monthAmountArray.map((monthAmount) => ({
            criteriaKey: monthAmount.month,
            maxAllowedValue: monthAmount.amount,
          })),
        },
      ],
      singleValueTransactionRules: [
        {
          strategyName: 'max_inv_per_month',
          maxAllowedValue: maxNumberOfInvoice,
          ruleKey: 'Maximum number of Invoices Allowed Per Month',
        },
        {
          strategyName: 'max_value_per_invoice',
          maxAllowedValue: maxValPerInvoice,
          ruleKey: 'Maximum allowed value per invoice',
        },
        {
          strategyName: 'max_days_less_than_today',
          maxAllowedValue: invoiceDate,
          ruleKey: 'Maximum days less than today',
        },
        {
          strategyName: 'max_invoices_in_seven_days',
          maxAllowedValue: maxNumberOfInvInSevenDays,
          ruleKey: 'Maximum number of invoices allowed in seven days',
        },
      ],
      limitAccountNumber: null,
    };
    POST(API_POST_ACTIVE_LIMIT, dataToSend)
      .then(async ({ data }) => {
        if (data.code === 200) {
          this.props.closeAction();
          await getLimitAccountNumber(userId);
          await getLimitSummary(userId);
        } else {
          alert('Something went wrong');
        }
      })
      .catch((err) => {
        console.error(err);
        alert('Something went wrong');
      })
      .finally(() => {
        actions.loaderModal(false);
      });
  };
  render() {
    const {
      monthAmountArray,
      maxNumberOfInvoice,
      maxNumberOfInvInSevenDays,
      maxValPerInvoice,
      invoiceDate,
    } = this.state;

    return (
      <div className='configure-transaction-wrapper'>
        <div className='heading'>Configure Transaction Rule</div>
        <div className='body'>
          <div className='row'>
            <div className='left-section align-self-start'>
              <div className='text '>
                Total Invoice Amount in low business month
              </div>
            </div>
            <div className='right-section d-block '>
              {monthAmountArray.map((monthAmount, index) => {
                return (
                  <div
                    className='d-flex align-items-center justify-content-between'
                    key={`month-amount-${index}`}
                  >
                    <div className='d-flex justify-content-between w-100 mb-4'>
                      <div className='w-100 align-self-start mr-5 '>
                        <label className='d-flex helper-text font-weight-bold'>
                          Month
                        </label>
                        <select
                          className={'select-box m-0'}
                          value={monthAmount.month}
                          name='debitCashFlowQuarter'
                          onChange={(event) =>
                            this.onInputChange(
                              event.target.value,
                              index,
                              'month'
                            )
                          }
                        >
                          {MONTHS.map((month) => {
                            return (
                              <option value={month} key={month}>
                                {month}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className='w-100'>
                        <label className='d-flex helper-text font-weight-bold'>
                          Sales Amount
                        </label>
                        <input
                          className='w-100'
                          min={0}
                          value={monthAmount.amount}
                          type='number'
                          onChange={(event) =>
                            this.onInputChange(
                              event.target.value,
                              index,
                              'amount'
                            )
                          }
                        />
                      </div>
                    </div>
                    <RiDeleteBin5Line
                      className='ml-5 cursor-pointer'
                      size={22}
                      onClick={() => this.onDeleteHandler(index)}
                    />
                  </div>
                );
              })}
            </div>
            <div className='d-flex justify-content-end w-100 mr-4'>
              <button className='add_button' onClick={this.addMoreMonthAmount}>
                Add More +
              </button>
            </div>
          </div>
          <div className='row'>
            <div className='left-section'>
              <div className='text'>
                Maximum number of invoices in a month ( #)
              </div>
            </div>
            <div className='right-section'>
              <input
                value={maxNumberOfInvoice}
                name='maxNumberOfInvoice'
                onChange={this.onSingleInputChange}
                type='number'
              />
              <div className='helper-text'>(in number)</div>
            </div>
          </div>
          <div className='row'>
            <div className='left-section'>
              <div className='text'>Maximum value per invoice</div>
            </div>
            <div className='right-section'>
              <input
                value={maxValPerInvoice}
                name='maxValPerInvoice'
                onChange={this.onSingleInputAmounthHandle}
                type='number'
              />
              <div className='helper-text'>(amount in rupees)</div>
            </div>
          </div>
          <div className='row'>
            <div className='left-section'>
              <div className='text'>Invoice Date not older than</div>
            </div>
            <div className='right-section'>
              <input
                value={invoiceDate}
                name='invoiceDate'
                onChange={this.onSingleInputChange}
                type='number'
              />
              <div className='helper-text'>(in days)</div>
            </div>
          </div>
          <div className='row'>
            <div className='left-section'>
              <div className='text'>Maximum number of invoices in 7 days</div>
            </div>
            <div className='right-section'>
              <input
                value={maxNumberOfInvInSevenDays}
                name='maxNumberOfInvInSevenDays'
                onChange={this.onSingleInputChange}
                type='number'
              />
              <div className='helper-text'>(in number)</div>
            </div>
          </div>
          <div className='buttons_wrapper'>
            <button className='cancel_button' onClick={this.onCancel}>
              Cancel
            </button>
            <button className='add_button ml-4' onClick={this.onActivateLimit}>
              Activate Limit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getLimitSummary,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(ConfigureTransactionRule);
