import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { repaymentColumns } from './interestAndCharges.config.js';
import { Helmet } from 'react-helmet';
import Button from '../../../components/Button';
import CredoModal from '../../../components/CredoModal';
import ManageCharges from '../../../components/Modals/ManageCharges.js';
import { connect } from 'react-redux';
import {
  getSoa,
  getSoaSummary,
  getAdminSoaSummary,
  getBusinessDetails,
} from './interestAndCharges.actions';
import { bindActionCreators } from 'redux';
import ExcelIcon from '../../../assets/images/excel.svg';
import PdfIcon from '../../../assets/images/pdf.svg';
import warning from '../../../assets/images/ph_warning.svg';
import WaiveOffModal from './waiveOffModal.js';
import { rupeeDecimalFormatter } from '../../../utils/utility';
import Wavier from '../../../assets/images/wavier.svg';
import UpArrow from '../../../assets/images/UpArrow.svg';
import DownArrow from '../../../assets/images/DownArrow.svg';
import UpArrowMark from '../../../assets/images/up_arrow.svg';
import DownArrowMark from '../../../assets/images/down_arrow.svg';
import InvoiceButton from '../../../assets/images/invoice-button.png';
import { loaderModal } from '../../../store/actions';
import {
  API_GET_EXPORT_SOA_EXCEL,
  API_ADMIN_SOA_PDF,
  API_POST_ADMIN_TABLE,
} from '../../../utils/APIUrls';
import axios from 'axios';
import FileDownload from 'js-file-download';
import InvoiceInterestDetails from './InvoiceInterestDetails.js';
import { getSmaDetails } from './interestAndCharges.actions';
import PostAccruedCharges from './PostAccruedModal.js';

class InterestAndCharges extends Component {
  state = {
    isModalOpen: false,
    showDatePicker: false,
    perPage: 10,
    totalPages: 0,
    pageOffSet: 0,
    currentPage: 1,
    sortTransactionDate: -1,
    sortEffectiveDate: -1,
    soaTable: [],
    endDate: null,
    startDate: null,
    sortingOn: 'effectiveDate',
  };

  componentDidMount = async () => {
    let { actions, match } = this.props,
      { userId, loanId } = match.params,
      { getAdminSoaSummary, getSmaDetails, getBusinessDetails } = actions,
      token = localStorage.getItem('token');

    if (token) {
      await this.soaAdminTable();
      await getAdminSoaSummary(userId);
      await getSmaDetails(userId);
      await getBusinessDetails(loanId, userId);
      actions.loaderModal(false);
    } else {
      let { history } = this.props;
      alert('Unauthorized access');
      actions.loaderModal(false);
      history.push('/');
    }
  };

  getPageData = (type) => {
    let { currentPage, totalPages, perPage, pageOffSet, soaTable } = this.state;
    if (type === 'prev' && currentPage > 1) {
      currentPage = currentPage - 1;
      pageOffSet = pageOffSet - 10;
      let slicedSoa = soaTable.slice(pageOffSet, pageOffSet + perPage);

      this.setState({
        currentPage,
        pageOffSet,
        slicedSoaData: slicedSoa,
      });
    } else if (type === 'next' && currentPage < totalPages) {
      pageOffSet = currentPage * perPage;
      let slicedSoa = soaTable.slice(pageOffSet, pageOffSet + perPage);
      this.setState({
        currentPage: currentPage + 1,
        pageOffSet,
        slicedSoaData: slicedSoa,
      });
    }
  };

  getTrProps = (state, rowInfo) => {
    if (rowInfo) {
      return {
        style: {
          backgroundColor:
            rowInfo?.original?.repayDetails &&
            Object.keys(rowInfo?.original?.repayDetails).length > 0
              ? '#057365'
              : '',
          color:
            rowInfo?.original?.repayDetails &&
            Object.keys(rowInfo?.original?.repayDetails).length > 0
              ? 'white'
              : 'black',
        },
      };
    }
    return {};
  };
  // getTheadThProps = (state, rowInfo, column, instance) => ({
  //   onClick: async () => {
  //     let { sortTransactionDate, sortEffectiveDate } = this.state,
  //       { actions, match } = this.props,
  //       { userId } = match.params;
  //     if (column?.id === 'effectiveDate') {
  //       await this.setState({
  //         sortEffectiveDate: sortEffectiveDate === -1 ? 1 : -1,
  //         sortTransactionDate: -1,
  //         sortingOn: 'effectiveDate',
  //         perPage: 10,
  //         totalPages: 0,
  //         pageOffSet: 0,
  //         currentPage: 1,
  //       });
  //     } else if (column?.id === 'transactionDate') {
  //       await this.setState({
  //         sortTransactionDate: sortTransactionDate === -1 ? 1 : -1,
  //         sortEffectiveDate: -1,
  //         sortingOn: 'transactionDate',
  //         perPage: 10,
  //         totalPages: 0,
  //         pageOffSet: 0,
  //         currentPage: 1,
  //       });
  //     }
  //     actions.loaderModal(true);
  //     await actions.getSoa(
  //       userId,
  //       this.state.sortingOn === 'transactionDate'
  //         ? this.state.sortTransactionDate
  //         : this.state.sortEffectiveDate,
  //       this.state.sortingOn
  //     );

  //     let slicedSoaData = this.props.soaData?.slice(
  //       this.state.pageOffSet,
  //       this.state.pageOffSet + this.state.perPage
  //     );
  //     this.setState({
  //       totalPages: Math.ceil(this.props.soaData?.length / this.state.perPage),
  //       slicedSoaData,
  //     });
  //     actions.loaderModal(false);
  //   },
  // });

  toggleModal = (modalType, rowData, modalName) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      rowData,
    });

    if (modalName === 'ManageCharges' && this.state.isModalOpen === true) {
      let { actions, match } = this.props,
        { userId } = match.params,
        { getSoaSummary, getSoa } = actions;

      getSoa(userId);
      getSoaSummary(userId);
    }
  };
  getDateFilterData = () => {
    this.setState((prevState) => ({
      ...prevState,
      showDatePicker: !prevState.showDatePicker,
      currentPage: 1,
    }));
    this.soaAdminTable();
  };

  soaDownload = async () => {
    let { sortingOn, sortTransactionDate, sortEffectiveDate } = this.state,
      sortParameter =
        sortingOn === 'transactionDate'
          ? sortTransactionDate
          : sortEffectiveDate;
    const token = localStorage.getItem('token'),
      { match, actions } = this.props,
      { userId } = match.params,
      headers = {
        'X-CLIENT-NAME': 'PDU',
        'X-USER-ID': localStorage.getItem('userId'),
        Authorization: `Bearer ${token}`,
      };
    actions.loaderModal(true);

    axios({
      url: `${API_GET_EXPORT_SOA_EXCEL}?userId=${userId}&sortParameter=${sortParameter}&sortingOn=${sortingOn}`,
      method: 'GET',
      headers: headers,
      responseType: 'blob', // Important
    }).then((res) => {
      actions.loaderModal(false);
      FileDownload(res.data, `soa_excel_${userId}.xlsx`);
    });
  };

  soaAdminPdfDownload = async () => {
    const { match, actions } = this.props,
      { userId } = match.params;
    let sendData = {
      format: 'ADMIN_USER_PDF',
      identifier: userId,
    };
    const token = localStorage.getItem('token');
    const xUserId = localStorage.getItem('userId');
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'X-CLIENT-NAME': 'PDU',
      'X-USER-ID': xUserId,
    };
    actions.loaderModal(true);
    axios
      .post(`${API_ADMIN_SOA_PDF}`, sendData, { headers })
      .then(async ({ data }) => {
        if (data?.code === 200) {
          const response = await fetch(data?.data?.s3URL);
          const blob = await response.blob(); //converting response into blob object
          FileDownload(blob, `soa_Admin_${userId}.pdf`); //blob passes into FileDownload
        } else {
          alert('Something went wrong');
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      })
      .finally(() => {
        actions.loaderModal(false);
      });
  };

  soaAdminTable = async () => {
    const { match, actions } = this.props,
      { userId } = match.params;
    const token = localStorage.getItem('token');
    const xUserId = localStorage.getItem('userId');
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'X-CLIENT-NAME': 'PDU',
      'X-USER-ID': xUserId,
    };
    let { startDate, endDate, pageOffSet, perPage } = this.state;
    let sendData = {
      from: startDate ? startDate?.split('-')?.reverse()?.join('/') : null,
      sortingRequest: {
        sortParameter: 1,
        sortingOn: 'createdDate',
      },
      to: endDate ? endDate?.split('-')?.reverse()?.join('/') : null,
      userId,
    };
    actions.loaderModal(true);
    axios
      .post(`${API_POST_ADMIN_TABLE}`, sendData, {
        headers,
      })
      .then(async ({ data }) => {
        if (data?.code === 200) {
          await this.setState({
            soaTable: data?.data, // set total soa table data
          });
          let slicedSoa = this.state.soaTable?.slice(
            pageOffSet,
            pageOffSet + perPage
          ); //  soa table update
          this.setState({
            totalPages: Math.ceil(this.state.soaTable?.length / perPage),
            slicedSoaData: slicedSoa,
          }); //setting total page and slicedSoaData
        } else {
          alert('Something went wrong');
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      })
      .finally(() => {
        actions.loaderModal(false);
      });
  };

  render() {
    let {
        isModalOpen,
        modalType,
        rowData,
        totalPages,
        currentPage,
        slicedSoaData,
        sortTransactionDate,
        showDatePicker,
      } = this.state,
      { soaAdminSummary, smaDetails, promoterData } = this.props,
      {
        accruedInterest,
        cashbackEarned,
        dueCharges,
        dueInterest,
        overdueCharges,
        grossOutstanding,
        minimumAmountDue,
        pendingInvoices,
        outStandingPrincipal,
        pendingCharges,
        overduePrincipal,
        totalOutstanding,
        excessBalance,
        overdueInterest,
      } = soaAdminSummary || {};

    const applicationColumns = [
      {
        Header: () => (
          <span>
            Transaction Date
            <img
              src={sortTransactionDate === -1 ? UpArrow : DownArrow}
              alt='arrow'
              className='sort_arrow'
            />
          </span>
        ),
        accessor: 'transDate',
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 230,
        Cell: ({ original }) => {
          let description = original?.description;
          let [transactionDescription, invoiceNumber, Date] =
            description?.split(' : ') || [];

          return (
            <>
              {transactionDescription ? (
                <div className='mt-3'>{transactionDescription}</div>
              ) : (
                ''
              )}
              {invoiceNumber ? (
                <span
                  className='invoice_number'
                  onClick={() => {
                    this.toggleModal(InvoiceInterestDetails, original);
                  }}
                >
                  {invoiceNumber}
                </span>
              ) : (
                ''
              )}
              {Date ? <span className='ml-2'>{Date}</span> : ''}
            </>
          );
        },
      },
      {
        Header: 'Due Date',
        accessor: 'dueDate',
      },
      {
        Header: 'Repaid Date',
        accessor: 'repayDate',
      },
      {
        Header: 'Debit',
        accessor: 'debitAmount',
        Cell: (props) => {
          return (
            <div className='ml-1'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
      {
        Header: 'Credit',
        accessor: 'creditAmount',

        Cell: (props) => {
          return (
            <div className='ml-1'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
      {
        Header: 'Outstanding',
        accessor: 'osAmount',
        Cell: (props) => {
          return (
            <div className='ml-1'>
              {rupeeDecimalFormatter(props?.value, '₹')}
            </div>
          );
        },
      },
      {
        Header: 'Waiver',
        headerStyle: { textAlign: 'center' },
        width: 60,
        Cell: ({ original }) => {
          let { entryType, descType } = original;
          return (
            <div style={{ textAlign: 'center' }}>
              {entryType === 'DEBIT' && descType === 'CHARGE' ? (
                <img
                  src={Wavier}
                  alt='icon'
                  className='wavier_img'
                  onClick={() => {
                    this.toggleModal(WaiveOffModal, original);
                  }}
                />
              ) : null}
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: '',
        expander: true,
        Expander: ({ isExpanded, ...row }) => {
          if (row.original?.repayDetails !== null) {
            return (
              <img
                alt='icon'
                style={{ height: '8px' }}
                src={isExpanded ? UpArrowMark : DownArrowMark}
              />
            );
          }
          return '';
        },
      },
    ];
    return (
      <>
        <div className='interest_charges_wrapper container'>
          <Helmet>
            <meta charSet='utf-8' />
            <title> Interest and Charges</title>
          </Helmet>
          <div className='row'>
            <div className='col-md-9'>
              <div className='card m-r-2'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h3 className='card_heading'>
                    <p>Summary</p>
                  </h3>
                  <div className='d-flex'>
                    {(smaDetails?.status === 'Blocked' ||
                      (smaDetails?.subCategories !== '0' &&
                        smaDetails?.status === 'Active')) && (
                      <>
                        <div
                          className={`chip ${smaDetails?.subCategories} m-3`}
                        >
                          {smaDetails?.subCategories}
                        </div>

                        <div className='chip Dpd m-3'>
                          <img
                            className='pb-1 pr-2'
                            src={warning}
                            alt='warning'
                          />{' '}
                          DPD {smaDetails?.dpdDays}
                        </div>
                      </>
                    )}
                    <div
                      className={`chip ${smaDetails?.status} m-3 d-flex align-items-center`}
                    >
                      <div className={`dot ${smaDetails?.status}`}></div>
                      {smaDetails?.status}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className='col-md-3 text-grey'>
                        Pending Invoices
                        <div className='text-bold'>
                          {pendingInvoices ?? '-'}
                        </div>
                      </div>
                      <div className='col-md-3 text-grey'>
                        Principal Outstanding
                        <div className='text-bold'>
                          <div className='text-bold'>
                            {rupeeDecimalFormatter(outStandingPrincipal, '₹')}
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 text-grey'>
                        Principal Overdue
                        <div className='text-bold'>
                          <div className='text-bold'>
                            {rupeeDecimalFormatter(overduePrincipal, '₹')}
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 text-grey'>
                        Accrued Interest
                        <div className='text-bold'>
                          <div className='text-bold'>
                            {rupeeDecimalFormatter(accruedInterest, '₹')}
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 text-grey'>
                        Overdue Interest
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(overdueInterest, '₹')}
                        </div>
                      </div>
                      <div className='col-md-3 text-grey'>
                        Due Interest (Posted)
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(dueInterest, '₹')}
                        </div>
                      </div>
                      <div className='col-md-3 text-grey'>
                        Charges (Not Posted)
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(pendingCharges, '₹')}
                        </div>
                      </div>
                      <div className='col-md-3 text-grey'>
                        Charges Due (Posted)
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(dueCharges, '₹')}
                        </div>
                      </div>
                      <div className='col-md-3 text-grey'>
                        Charges Overdue
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(overdueCharges, '₹')}
                        </div>
                      </div>
                      <div className='col-md-3 text-grey'>
                        Cashback Earned
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(cashbackEarned, '₹')}
                        </div>
                      </div>
                      <div className='col-md-3 text-grey'>
                        Total Outstanding (Without Accured Entries)
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(totalOutstanding, '₹')}
                        </div>
                      </div>
                      <div className='col-md-3 text-grey'>
                        Excess Balance
                        <div className='text-bold'>
                          {rupeeDecimalFormatter(excessBalance)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3 p-left'>
              <div className='row card_secondary card_secondary_orange'>
                <div className='col-md-12'>Minimum Amount Due</div>
                <div className='col-md-12 card_secondary_value'>
                  {rupeeDecimalFormatter(minimumAmountDue, '₹')}
                </div>
              </div>
              <div className='row card_secondary card_secondary_green'>
                <div className='col-md-12'>Gross Outstanding</div>
                <div className='col-md-12 card_secondary_value'>
                  {rupeeDecimalFormatter(grossOutstanding, '₹')}
                </div>
              </div>
              <div className='row mt-3 ml-2 pr-2'></div>
              <div className='row mt-3 ml-2 pr-2'>
                <Button
                  variant='outlined'
                  onClick={() => {
                    this.toggleModal(PostAccruedCharges);
                  }}
                >
                  Post Accured Entries
                </Button>
              </div>
              <div className='row mt-3 ml-2 pr-2'>
                <Button
                  variant='outlined'
                  onClick={() => {
                    this.toggleModal(ManageCharges);
                  }}
                >
                  Manage Charges
                </Button>
              </div>
            </div>
          </div>

          <div className='card m-t-0'>
            <div className='card_heading_table row'>
              <h3 className='col-md-10 card_heading_secondary'>
                SOA
                <a onClick={this.soaDownload} download>
                  <img src={ExcelIcon} alt='icon' className='icon_image' />
                </a>
                <img
                  src={PdfIcon}
                  alt='icon'
                  className='icon_image'
                  onClick={this.soaAdminPdfDownload}
                />
              </h3>
              <h5 className='col-md-2 pt-2  card_heading_secondary_right'>
                <img
                  src={InvoiceButton}
                  alt='icon'
                  className='icon_image mt-4'
                  onClick={() =>
                    this.setState((prevState) => ({
                      ...prevState,
                      showDatePicker: !prevState.showDatePicker,
                    }))
                  }
                />
              </h5>
            </div>

            {showDatePicker ? (
              <div className='row d-flex justify-content-end'>
                <div className='card date-picker col-4'>
                  <div className='row  '>
                    <div className='col text-grey mt-2'>
                      Start Date
                      <input
                        className='date'
                        type='date'
                        onChange={(e) =>
                          this.setState({
                            startDate: e.target.value,
                          })
                        }
                      ></input>
                    </div>
                    <div className='col text-grey mt-2'>
                      End Date
                      <input
                        className='date'
                        type='date'
                        onChange={(e) =>
                          this.setState({
                            endDate: e.target.value,
                          })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className='row mt-3 ml-2'>
                    <Button onClick={() => this.getDateFilterData()}>OK</Button>
                  </div>
                </div>
              </div>
            ) : null}

            <ReactTable
              data={slicedSoaData}
              columns={applicationColumns}
              minRows={10}
              showPagination={false}
              getTrProps={this.getTrProps}
              sortable={false}
              getTheadThProps={this.getTheadThProps}
              SubComponent={(row) => {
                const allRepayments = Object?.values(
                  row?.original?.repayDetails
                ).flat(); //to extract all the values and then flattening the resulting array.
                return (
                  <div className='repayment_table_wrapper'>
                    <ReactTable
                      data={allRepayments || []}
                      columns={
                        row.original?.repayDetails ? repaymentColumns : []
                      }
                      minRows={0}
                      showPagination={false}
                    />
                  </div>
                );
              }}
            />
            <ul className={'datatable-pagination'}>
              <li>
                <button
                  className={'prev-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('prev')}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {currentPage} of {totalPages}
                </span>
              </li>
              <li>
                <button
                  className={'next-btn'}
                  type={'button'}
                  onClick={() => this.getPageData('next')}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>

        <CredoModal
          isOpen={isModalOpen}
          RenderingComponent={modalType}
          title={
            modalType?.WrappedComponent?.name === 'ManageCharges'
              ? 'Manage Charges'
              : null
          }
          styles={
            modalType === ManageCharges || modalType === InvoiceInterestDetails
              ? {
                  content: {
                    width: '90vw',
                    height: '90vh',
                  },
                }
              : modalType === PostAccruedCharges
              ? {
                  content: {
                    width: '60rem',
                    height: '30rem',
                  },
                }
              : {
                  content: {
                    width: '60vw',
                    height: '60vh',
                  },
                }
          }
          rowData={rowData}
          closeAction={() => {
            modalType === ManageCharges
              ? this.toggleModal(modalType, '', 'ManageCharges')
              : this.toggleModal(modalType, '', '');
          }}
          toggleModal={this.toggleModal}
          {...this.props}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  interestAndChargesReducer,
  leadManagementNavsReducer,
  ApplicationReducer,
}) => ({
  soaData: interestAndChargesReducer.soaData,
  soaSummary: interestAndChargesReducer.soaSummary,
  soaAdminSummary: interestAndChargesReducer.soaAdminSummary,
  smaDetails: interestAndChargesReducer.smaDetails,
  promoterData: ApplicationReducer.promoterData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getSoa,
      getSoaSummary,
      loaderModal,
      getAdminSoaSummary,
      getSmaDetails,
      getBusinessDetails,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterestAndCharges);
